import React from "react";
import { StaticQuery, graphql } from "gatsby";

import FaqContainer from "../components/Custom/Faq/FaqContainer";

const Faq = ({ data }) => {
  return (
    <div>
      <h1>Faq</h1>
      <FaqContainer faqs={data.allGoogleSpreadsheetFaq.edges} />
    </div>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allGoogleSpreadsheetFaq {
          edges {
            node {
              id
              question
              answer
              moreLink
            }
          }
        }
      }
    `}
    render={data => <Faq data={data} {...props} />}
  />
);
