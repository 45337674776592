import React from "react";
import { CardBody, CardHeader, Collapse } from "reactstrap";

const FaqCard = ({ faq, collapses, changeCollapse }) => {
  return (
    <>
      <CardHeader className="card-collapse" id="headingOne" role="tab">
        <h5 className="mb-0 panel-title">
          <a
            aria-expanded={collapses.includes(faq.id)}
            className="collapsed"
            data-parent="#accordion"
            href="#pablo"
            id={`Collapse${faq.id}`}
            onClick={e => {
              e.preventDefault();
              changeCollapse(faq.id);
            }}
          >
            {faq.question} <i className="nc-icon nc-minimal-down" />
          </a>
        </h5>
      </CardHeader>
      <Collapse isOpen={collapses.includes(faq.id)}>
        <CardBody>
           <div
           className="content-drive content-terms"
           dangerouslySetInnerHTML={{
             __html: faq.answer
           }}
         />
          {faq.moreLink !== null && faq.moreLink !== undefined && faq.moreLink !== ' ' ? (
            <>
              <br />
              <br />
              <a href={faq.moreLink} target="_blank" rel="noopener noreferrer">
                Mehr
              </a>
            </>
          ) : (
            <></>
          )}
        </CardBody>
      </Collapse>
    </>
  );
};

export default FaqCard;
