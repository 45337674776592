import React from "react";
import { Card } from "reactstrap";
import FaqCard from "./FaqCard";
import { graphql, StaticQuery } from "gatsby";

const FaqContainer = ({ faqs }) => {
  console.log(faqs);
  const [collapses, setCollapses] = React.useState([]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const renderCards = () => {
    if (faqs.length > 0) {
      return faqs.map(faq => {
        return (
          <FaqCard
            key={faq.node.id}
            faq={faq.node}
            collapses={collapses}
            changeCollapse={changeCollapse}
          />
        );
      });
    }
    return <></>;
  };

  return (
    <>
      <div id="acordeon">
        <div aria-multiselectable={true} id="accordion" role="tablist">
          <Card className="no-transition card-citylove-accordeon">{renderCards()}</Card>
        </div>
      </div>
    </>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allGoogleSpreadsheetFaq {
          edges {
            node {
              id
              question
              answer
              moreLink
            }
          }
        }
      }
    `}
    render={data => (
      <FaqContainer faqs={data.allGoogleSpreadsheetFaq.edges} {...props} />
    )}
  />
);
